import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IQuotation, IQuotationListItem } from 'app/models/quotations.models'
import { IFile } from 'app/models/files.model'

export const QuotationGetQuery = async (token: string): Promise<IQuotation> => {
  return checkResponse(
    await fetch(
      getUrl(`quotations/?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}


export const QuotationListQuery = async (enterprise: number): Promise<IQuotationListItem[]> => {
  return checkResponse(
  await fetch(getUrl(`clientspace/enterprises/${enterprise}/quotations`), getRequestInit(true))
  )
}


export const QuotationDownloadQuery = async (token: string): Promise<IFile> => {
  return checkResponse(
    await fetch(getUrl(`quotations/pdf?token=${token}`), getRequestInit(true))
  )
}